import { RouteRecordRaw } from "vue-router";

export const toolsRoutes: RouteRecordRaw[] = [
  {
    path: "create-content",
    name: "create-content",
    redirect: { name: "content" },
    meta: {
      title: "Create content with AI",
      added: "2022-08-04",
      updated: "2023-06-01",
      text: "Create content with AI",
      icon: "wand-magic",
      description:
        "Use Fyr's advanced machine learning algorithms to help you create new content ",
    },
    component: () => import("@/views/Tools/ContentLab.vue"),
    children: [
      {
        path: "content",
        name: "content",
        meta: { title: "Create content with AI" },
        component: () => import("@/views/Tools/ContentLab/ContentCreator.vue"),
      },
    ],
  },
  {
    path: "utm-builder",
    name: "utm-builder",
    meta: {
      title: "UTM URL Builder",
      text: "UTM URL builder",
      description:
        "Get insight about where users are coming from and what engages users",
      icon: "mouse-pointer",
    },
    component: () => import("@/views/Tools/UtmBuilder.vue"),
  },
];
